import { DragDropModule } from '@angular/cdk/drag-drop';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AccountService } from './account/account.service';
import { UserService } from './user/user.service';
import { SiteService } from './site/site.service';
import { AdUnitService } from './ad-unit/ad-unit.service';
import { MainComponent } from './main.component';

import { SharedModule } from '@shared/shared.module';
import { ToasterService } from '@core/services/toaster.service';
import { ChangeHistoryService } from './change-history/change-history.service';
import { SidenavModule } from '@shared/sidenav/sidenav.module';
import { MainRoutingModule } from '@main/main-routing.module';
import { PWAService } from './pwa/pwa.service';
import { AppsService } from './apps/apps.service';
import { AtsService } from './ats/ats.service';
import { LoadingModule } from '../shared/loading/loading.module';

@NgModule({
  declarations: [MainComponent],
  imports: [SharedModule, RouterModule, NgxSpinnerModule, SidenavModule, DragDropModule, MainRoutingModule, LoadingModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AccountService,
    AdUnitService,
    SiteService,
    UserService,
    ToasterService,
    ChangeHistoryService,
    PWAService,
    AppsService,
    AtsService,
  ],
  exports: [MainComponent],
})
export class MainModule {}
