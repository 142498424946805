import { SessionStatus } from '@sentry/types';
import { dropUndefinedKeys, uuid4 } from '@sentry/utils';
/**
 * @inheritdoc
 */
var Session = /** @class */function () {
  function Session(context) {
    this.errors = 0;
    this.sid = uuid4();
    this.timestamp = Date.now();
    this.started = Date.now();
    this.duration = 0;
    this.status = SessionStatus.Ok;
    if (context) {
      this.update(context);
    }
  }
  /** JSDoc */
  // eslint-disable-next-line complexity
  Session.prototype.update = function (context) {
    if (context === void 0) {
      context = {};
    }
    if (context.user) {
      if (context.user.ip_address) {
        this.ipAddress = context.user.ip_address;
      }
      if (!context.did) {
        this.did = context.user.id || context.user.email || context.user.username;
      }
    }
    this.timestamp = context.timestamp || Date.now();
    if (context.sid) {
      // Good enough uuid validation. — Kamil
      this.sid = context.sid.length === 32 ? context.sid : uuid4();
    }
    if (context.did) {
      this.did = "" + context.did;
    }
    if (typeof context.started === 'number') {
      this.started = context.started;
    }
    if (typeof context.duration === 'number') {
      this.duration = context.duration;
    } else {
      this.duration = this.timestamp - this.started;
    }
    if (context.release) {
      this.release = context.release;
    }
    if (context.environment) {
      this.environment = context.environment;
    }
    if (context.ipAddress) {
      this.ipAddress = context.ipAddress;
    }
    if (context.userAgent) {
      this.userAgent = context.userAgent;
    }
    if (typeof context.errors === 'number') {
      this.errors = context.errors;
    }
    if (context.status) {
      this.status = context.status;
    }
  };
  /** JSDoc */
  Session.prototype.close = function (status) {
    if (status) {
      this.update({
        status: status
      });
    } else if (this.status === SessionStatus.Ok) {
      this.update({
        status: SessionStatus.Exited
      });
    } else {
      this.update();
    }
  };
  /** JSDoc */
  Session.prototype.toJSON = function () {
    return dropUndefinedKeys({
      sid: "" + this.sid,
      init: true,
      started: new Date(this.started).toISOString(),
      timestamp: new Date(this.timestamp).toISOString(),
      status: this.status,
      errors: this.errors,
      did: typeof this.did === 'number' || typeof this.did === 'string' ? "" + this.did : undefined,
      duration: this.duration,
      attrs: dropUndefinedKeys({
        release: this.release,
        environment: this.environment,
        ip_address: this.ipAddress,
        user_agent: this.userAgent
      })
    });
  };
  return Session;
}();
export { Session };
