import { __read, __spread } from "tslib";
import { addGlobalEventProcessor, getCurrentHub } from '@sentry/hub';
import { logger } from '@sentry/utils';
export var installedIntegrations = [];
/** Gets integration to install */
export function getIntegrationsToSetup(options) {
  var defaultIntegrations = options.defaultIntegrations && __spread(options.defaultIntegrations) || [];
  var userIntegrations = options.integrations;
  var integrations = [];
  if (Array.isArray(userIntegrations)) {
    var userIntegrationsNames_1 = userIntegrations.map(function (i) {
      return i.name;
    });
    var pickedIntegrationsNames_1 = [];
    // Leave only unique default integrations, that were not overridden with provided user integrations
    defaultIntegrations.forEach(function (defaultIntegration) {
      if (userIntegrationsNames_1.indexOf(defaultIntegration.name) === -1 && pickedIntegrationsNames_1.indexOf(defaultIntegration.name) === -1) {
        integrations.push(defaultIntegration);
        pickedIntegrationsNames_1.push(defaultIntegration.name);
      }
    });
    // Don't add same user integration twice
    userIntegrations.forEach(function (userIntegration) {
      if (pickedIntegrationsNames_1.indexOf(userIntegration.name) === -1) {
        integrations.push(userIntegration);
        pickedIntegrationsNames_1.push(userIntegration.name);
      }
    });
  } else if (typeof userIntegrations === 'function') {
    integrations = userIntegrations(defaultIntegrations);
    integrations = Array.isArray(integrations) ? integrations : [integrations];
  } else {
    integrations = __spread(defaultIntegrations);
  }
  // Make sure that if present, `Debug` integration will always run last
  var integrationsNames = integrations.map(function (i) {
    return i.name;
  });
  var alwaysLastToRun = 'Debug';
  if (integrationsNames.indexOf(alwaysLastToRun) !== -1) {
    integrations.push.apply(integrations, __spread(integrations.splice(integrationsNames.indexOf(alwaysLastToRun), 1)));
  }
  return integrations;
}
/** Setup given integration */
export function setupIntegration(integration) {
  if (installedIntegrations.indexOf(integration.name) !== -1) {
    return;
  }
  integration.setupOnce(addGlobalEventProcessor, getCurrentHub);
  installedIntegrations.push(integration.name);
  logger.log("Integration installed: " + integration.name);
}
/**
 * Given a list of integration instances this installs them all. When `withDefaults` is set to `true` then all default
 * integrations are added unless they were already provided before.
 * @param integrations array of integration instances
 * @param withDefault should enable default integrations
 */
export function setupIntegrations(options) {
  var integrations = {};
  getIntegrationsToSetup(options).forEach(function (integration) {
    integrations[integration.name] = integration;
    setupIntegration(integration);
  });
  return integrations;
}
