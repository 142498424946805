@if (showLoadingBar) {
  <mat-progress-bar class="loading-bar" color="accent" mode="indeterminate"></mat-progress-bar>
}
<div id="wrapper" [class]="getDarkClasses()" class="tw-flex tw-flex-row">
  @if (authService.isAuthenticated && !settings.layout.hideSidebar) {
    <div class="app-menu-bar tw-flex tw-flex-row tw-items-stretch tw-justify-start">
      @if (authService.isAuthenticated && !authService.is_maintain) {
        <app-sidenav></app-sidenav>
      }
    </div>
  }
  <!-- 'content-wrapper-wider': true, -->
  <div
    [ngClass]="{
      'full-width': !authService.isAuthenticated || authService.is_maintain,
      'content-wrapper-full': settings.layout.hideSidebar
    }"
    class="content-wrapper tw-flex tw-flex-col tw-items-stretch tw-justify-start"
  >
    @if (authService.isAuthenticated && !authService.is_maintain) {
      <app-toolbar id="app-toolbar" [ngClass]="settings.colorClasses.toolbar" class="below tw-flex tw-flex-row"></app-toolbar>
    }
    <div class="inner-content tw-flex tw-flex-col tw-items-stretch tw-justify-start tw-flex-1f">
      <router-outlet></router-outlet>
      @if (authService.isAuthenticated && !authService.is_maintain) {
        <app-footer [ngClass]="settings.colorClasses.footer" class="below"></app-footer>
      }
      <app-loading [showLoading]="isLoading$ | async" backdrop></app-loading>
    </div>
  </div>
</div>