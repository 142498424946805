import { SentryError } from './error';
import { SyncPromise } from './syncpromise';
/** A simple queue that holds promises. */
var PromiseBuffer = /** @class */function () {
  function PromiseBuffer(_limit) {
    this._limit = _limit;
    /** Internal set of queued Promises */
    this._buffer = [];
  }
  /**
   * Says if the buffer is ready to take more requests
   */
  PromiseBuffer.prototype.isReady = function () {
    return this._limit === undefined || this.length() < this._limit;
  };
  /**
   * Add a promise to the queue.
   *
   * @param task Can be any PromiseLike<T>
   * @returns The original promise.
   */
  PromiseBuffer.prototype.add = function (task) {
    var _this = this;
    if (!this.isReady()) {
      return SyncPromise.reject(new SentryError('Not adding Promise due to buffer limit reached.'));
    }
    if (this._buffer.indexOf(task) === -1) {
      this._buffer.push(task);
    }
    task.then(function () {
      return _this.remove(task);
    }).then(null, function () {
      return _this.remove(task).then(null, function () {
        // We have to add this catch here otherwise we have an unhandledPromiseRejection
        // because it's a new Promise chain.
      });
    });
    return task;
  };
  /**
   * Remove a promise to the queue.
   *
   * @param task Can be any PromiseLike<T>
   * @returns Removed promise.
   */
  PromiseBuffer.prototype.remove = function (task) {
    var removedTask = this._buffer.splice(this._buffer.indexOf(task), 1)[0];
    return removedTask;
  };
  /**
   * This function returns the number of unresolved promises in the queue.
   */
  PromiseBuffer.prototype.length = function () {
    return this._buffer.length;
  };
  /**
   * This will drain the whole queue, returns true if queue is empty or drained.
   * If timeout is provided and the queue takes longer to drain, the promise still resolves but with false.
   *
   * @param timeout Number in ms to wait until it resolves with false.
   */
  PromiseBuffer.prototype.drain = function (timeout) {
    var _this = this;
    return new SyncPromise(function (resolve) {
      var capturedSetTimeout = setTimeout(function () {
        if (timeout && timeout > 0) {
          resolve(false);
        }
      }, timeout);
      SyncPromise.all(_this._buffer).then(function () {
        clearTimeout(capturedSetTimeout);
        resolve(true);
      }).then(null, function () {
        resolve(true);
      });
    });
  };
  return PromiseBuffer;
}();
export { PromiseBuffer };
