import { __assign } from "tslib";
import { addGlobalEventProcessor, getCurrentHub } from '@sentry/core';
import { getGlobalObject } from '@sentry/utils';
var global = getGlobalObject();
/** UserAgent */
var UserAgent = /** @class */function () {
  function UserAgent() {
    /**
     * @inheritDoc
     */
    this.name = UserAgent.id;
  }
  /**
   * @inheritDoc
   */
  UserAgent.prototype.setupOnce = function () {
    addGlobalEventProcessor(function (event) {
      var _a, _b, _c;
      if (getCurrentHub().getIntegration(UserAgent)) {
        // if none of the information we want exists, don't bother
        if (!global.navigator && !global.location && !global.document) {
          return event;
        }
        // grab as much info as exists and add it to the event
        var url = ((_a = event.request) === null || _a === void 0 ? void 0 : _a.url) || ((_b = global.location) === null || _b === void 0 ? void 0 : _b.href);
        var referrer = (global.document || {}).referrer;
        var userAgent = (global.navigator || {}).userAgent;
        var headers = __assign(__assign(__assign({}, (_c = event.request) === null || _c === void 0 ? void 0 : _c.headers), referrer && {
          Referer: referrer
        }), userAgent && {
          'User-Agent': userAgent
        });
        var request = __assign(__assign({}, url && {
          url: url
        }), {
          headers: headers
        });
        return __assign(__assign({}, event), {
          request: request
        });
      }
      return event;
    });
  };
  /**
   * @inheritDoc
   */
  UserAgent.id = 'UserAgent';
  return UserAgent;
}();
export { UserAgent };
