import { Inject, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl {
  public constructor(@Inject(I18NEXT_SERVICE) private translate: ITranslationService) {
    super();

    this.translate.events.languageChanged.subscribe(() => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  public getAndInitTranslations(): void {
    this.itemsPerPageLabel = this.translate.t('report:ITEMS_PER_PAGE');
    this.nextPageLabel = this.translate.t('PAGINATION.NEXT_PAGE_LABEL');
    this.previousPageLabel = this.translate.t('PAGINATION.PREVIOUS_PAGE_LABEL');
    this.firstPageLabel = this.translate.t('PAGINATION.FIRST_PAGE_LABEL');
    this.lastPageLabel = this.translate.t('PAGINATION.LAST_PAGE_LABEL');
  }
}
