import { __read, __spread } from "tslib";
import { addGlobalEventProcessor, getCurrentHub } from '@sentry/core';
import { isInstanceOf } from '@sentry/utils';
import { exceptionFromStacktrace } from '../parsers';
import { computeStackTrace } from '../tracekit';
var DEFAULT_KEY = 'cause';
var DEFAULT_LIMIT = 5;
/** Adds SDK info to an event. */
var LinkedErrors = /** @class */function () {
  /**
   * @inheritDoc
   */
  function LinkedErrors(options) {
    if (options === void 0) {
      options = {};
    }
    /**
     * @inheritDoc
     */
    this.name = LinkedErrors.id;
    this._key = options.key || DEFAULT_KEY;
    this._limit = options.limit || DEFAULT_LIMIT;
  }
  /**
   * @inheritDoc
   */
  LinkedErrors.prototype.setupOnce = function () {
    addGlobalEventProcessor(function (event, hint) {
      var self = getCurrentHub().getIntegration(LinkedErrors);
      if (self) {
        return self._handler(event, hint);
      }
      return event;
    });
  };
  /**
   * @inheritDoc
   */
  LinkedErrors.prototype._handler = function (event, hint) {
    if (!event.exception || !event.exception.values || !hint || !isInstanceOf(hint.originalException, Error)) {
      return event;
    }
    var linkedErrors = this._walkErrorTree(hint.originalException, this._key);
    event.exception.values = __spread(linkedErrors, event.exception.values);
    return event;
  };
  /**
   * @inheritDoc
   */
  LinkedErrors.prototype._walkErrorTree = function (error, key, stack) {
    if (stack === void 0) {
      stack = [];
    }
    if (!isInstanceOf(error[key], Error) || stack.length + 1 >= this._limit) {
      return stack;
    }
    var stacktrace = computeStackTrace(error[key]);
    var exception = exceptionFromStacktrace(stacktrace);
    return this._walkErrorTree(error[key], key, __spread([exception], stack));
  };
  /**
   * @inheritDoc
   */
  LinkedErrors.id = 'LinkedErrors';
  return LinkedErrors;
}();
export { LinkedErrors };
