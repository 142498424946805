/* eslint-disable @typescript-eslint/no-explicit-any */
import { consoleSandbox, getGlobalObject } from './misc';
// TODO: Implement different loggers for different environments
var global = getGlobalObject();
/** Prefix for logging strings */
var PREFIX = 'Sentry Logger ';
/** JSDoc */
var Logger = /** @class */function () {
  /** JSDoc */
  function Logger() {
    this._enabled = false;
  }
  /** JSDoc */
  Logger.prototype.disable = function () {
    this._enabled = false;
  };
  /** JSDoc */
  Logger.prototype.enable = function () {
    this._enabled = true;
  };
  /** JSDoc */
  Logger.prototype.log = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    if (!this._enabled) {
      return;
    }
    consoleSandbox(function () {
      global.console.log(PREFIX + "[Log]: " + args.join(' '));
    });
  };
  /** JSDoc */
  Logger.prototype.warn = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    if (!this._enabled) {
      return;
    }
    consoleSandbox(function () {
      global.console.warn(PREFIX + "[Warn]: " + args.join(' '));
    });
  };
  /** JSDoc */
  Logger.prototype.error = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    if (!this._enabled) {
      return;
    }
    consoleSandbox(function () {
      global.console.error(PREFIX + "[Error]: " + args.join(' '));
    });
  };
  return Logger;
}();
// Ensure we only have a single logger instance, even if multiple versions of @sentry/utils are being used
global.__SENTRY__ = global.__SENTRY__ || {};
var logger = global.__SENTRY__.logger || (global.__SENTRY__.logger = new Logger());
export { logger };
