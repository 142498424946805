import { logger, SentryError } from '@sentry/utils';
import { NoopTransport } from './transports/noop';
/**
 * This is the base implemention of a Backend.
 * @hidden
 */
var BaseBackend = /** @class */function () {
  /** Creates a new backend instance. */
  function BaseBackend(options) {
    this._options = options;
    if (!this._options.dsn) {
      logger.warn('No DSN provided, backend will not do anything.');
    }
    this._transport = this._setupTransport();
  }
  /**
   * @inheritDoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  BaseBackend.prototype.eventFromException = function (_exception, _hint) {
    throw new SentryError('Backend has to implement `eventFromException` method');
  };
  /**
   * @inheritDoc
   */
  BaseBackend.prototype.eventFromMessage = function (_message, _level, _hint) {
    throw new SentryError('Backend has to implement `eventFromMessage` method');
  };
  /**
   * @inheritDoc
   */
  BaseBackend.prototype.sendEvent = function (event) {
    this._transport.sendEvent(event).then(null, function (reason) {
      logger.error("Error while sending event: " + reason);
    });
  };
  /**
   * @inheritDoc
   */
  BaseBackend.prototype.sendSession = function (session) {
    if (!this._transport.sendSession) {
      logger.warn("Dropping session because custom transport doesn't implement sendSession");
      return;
    }
    this._transport.sendSession(session).then(null, function (reason) {
      logger.error("Error while sending session: " + reason);
    });
  };
  /**
   * @inheritDoc
   */
  BaseBackend.prototype.getTransport = function () {
    return this._transport;
  };
  /**
   * Sets up the transport so it can be used later to send requests.
   */
  BaseBackend.prototype._setupTransport = function () {
    return new NoopTransport();
  };
  return BaseBackend;
}();
export { BaseBackend };
