import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { FR_TRADITIONAL_LANG } from '@core/config/const/language';
import { registerAngularLocales } from '@core/i18next-translate/translation';
import { AuthService } from '@core/services/auth.service';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { TranslationLoaderService } from '@core/services/translation-loader.service';
import { ICON_REGISTRIES, SIDE_MENU_ICON_REGISTRIES } from '@src/app/icons';
import { isAfter } from 'date-fns';
import { filter, retry } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { environment } from '../environments/environment';
import { getEndMaintainPeriod, getStartMaintainPeriod, toUtcDate } from './core/utils/date-utc';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  set showMaintainBanner(value: boolean) {
    this.authService.showMaintainBanner.next(value);
  }

  constructor(
    public splashScreen: SplashScreenService,
    private translationLoader: TranslationLoaderService,
    private authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.loadIcons();
    this.translationLoader.setLanguage();
    // Register (web socket listener || apollo ws link) here
    this.connectMaintainWs();
    // notify 24h before maintenance
    this.calculateTimeToDisplayBanner();
    //  the veracity of a token
    this.refreshToken();
    registerAngularLocales(this.translationLoader.getCurrentLanguage());
  }

  private connectMaintainWs(): void {
    const maintainUrl = '/pages/under-maintenance';
    if (this.authService.is_maintain) {
      this.router.navigate([maintainUrl]);
    }
    const ws: WebSocketSubject<any> = webSocket<any>(`${environment.ws}/maintenance/`);

    ws.pipe(retry()).subscribe({
      next: (msg: any) => {
        localStorage.setItem(this.authService.isMaintainKey, msg?.maintenance_ongoing);

        if (msg?.maintenance_ongoing) {
          this.router.navigate([maintainUrl]);
        } else {
          this.router.url.includes('under-maintenance') ? this.router.navigate(['']) : null;
        }
        this.cdr.markForCheck();
      },
      error: (err) => {
        return this.router.url.includes('under-maintenance') ? this.router.navigate(['']) : null;
      },
    });

    ws.next(JSON.stringify({ msg: 'Hello AM Server!' }));
  }

  private calculateTimeToDisplayBanner(): void {
    const utcNow = toUtcDate(new Date());
    const startPeriod = getStartMaintainPeriod(utcNow);
    const endPeriod = getEndMaintainPeriod(utcNow);

    this.showMaintainBanner = isAfter(utcNow, startPeriod) && isAfter(endPeriod, utcNow);
  }

  loadIcons() {
    ICON_REGISTRIES.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url));
    });
    SIDE_MENU_ICON_REGISTRIES.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url));
    });
  }

  ngOnInit(): void {
    this.loadGtag();
    this.loadClarityTag();
    this.scheduleReload();
    this.loadCrowdinInContext();
  }

  scheduleReload() {
    const now = new Date(),
      nextDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 2, 0, 0);
    setInterval(() => {
      const timeNow = new Date().getTime();
      if (timeNow > nextDay.getTime()) {
        // linter doesn't like it, but there's no alternative to {forceReload} parameter
        // so keep it until there will be the other way
        window.location.reload();
      }
    }, 5000);
  }

  private loadGtag(): void {
    if (environment.gtagGA4MeasurementId) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtagGA4MeasurementId}`;
      script.async = false;
      script.addEventListener('load', () => {
        gtag('js', new Date());
        // tracking data with user id
        const user = this.authService.authenticated();
        if (user && user.value.id) {
          const user_id = this.authService.previousUserIndex ? `${this.authService.previousUser.id}~${user.value.id}` : `${user.value.id}`;
          gtag('set', { user_id });
        }
        const navEndEvents = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
        navEndEvents.subscribe((event: NavigationEnd) => {
          gtag('config', environment.gtagGA4MeasurementId, {
            page_path: event.urlAfterRedirects,
          });
        });
      });
      document.body.appendChild(script);
    }
  }

  private loadClarityTag(): void {
    if (environment.clarityTagId) {
      const script = document.createElement('script');
      script.innerText = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${environment.clarityTagId}");`;
      document.body.appendChild(script);
    }
  }

  private loadCrowdinInContext() {
    if (this.translationLoader.getCurrentLanguage()?.id === FR_TRADITIONAL_LANG.id) {
      const scriptSource = document.createElement('script');
      scriptSource.innerText = `var _jipt = []; _jipt.push(['project', 'anymanager']);`;
      const scriptContextLink = document.createElement('script');
      scriptContextLink.src = `//cdn.crowdin.com/jipt/jipt.js`;
      document.body.appendChild(scriptSource);
      document.body.appendChild(scriptContextLink);
    }
  }

  /**
   * API View that checks the veracity of a token, returning the token if it is valid.
   */
  private refreshToken() {
    const jwt_token = this.authService.adpp_jwt_token;

    // break if you have no jwt_token
    if (!jwt_token) {
      return;
    }

    // check jwt_token with APIs
    this.authService.refreshToken(jwt_token).subscribe({
      error: () => {
        this.clearToken();
      },
    });
  }

  /**
   * go to login page if have no adpp_jwt_token
   */
  private clearToken(): void {
    this.authService.logout();
  }
}
