import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@core/services/auth.service';
import { IUserInfo } from '@core/auth/auth.model';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  public sentryEnabled: boolean = false;
  public chunkFailedMessage = /Loading chunk [\d]+ failed/;

  constructor(private injector: Injector) {
    this.sentryEnabled = environment.sentryEnabled || false;
    if (this.sentryEnabled) {
      Sentry.init({
        dsn: 'https://5b6c7843a6e84be6ae097f66f4d82a8d@sentry.io/1451614',
        environment: environment.name,
        sampleRate: environment.production ? 0.5 : 0.25,
      });
    }
  }

  private _authService: AuthService = null;

  get authService(): AuthService {
    if (this._authService) {
      return this._authService;
    }
    this._authService = this.injector.get(AuthService);
    return this._authService;
  }

  get userData(): Partial<IUserInfo> {
    const authService = this.authService;
    let user = null;
    if (authService.user && authService.user.value) {
      // avoid changing the original object
      user = JSON.parse(JSON.stringify(authService.user.value));
      // remove some token sensitive data
      delete user['exp'];
      delete user['orig_iat'];
      delete user['iss'];
    } else {
      user = { name: 'Anonymous' };
    }
    return user;
  }

  get environmentData(): any {
    // avoid changing the original object
    return JSON.parse(JSON.stringify(environment));
  }

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} \n with body "${error.error}" \n headers: ${JSON.stringify(error.headers)}`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error) {
    if (this.chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else if (this.sentryEnabled) {
      const extractedError = this.extractError(error) || 'Handled unknown error';
      Sentry.withScope((scope) => {
        const user = this.userData;
        scope.setExtra('User Info', user);
        scope.setExtra('FE Environment', this.environmentData);
        scope.setUser({
          id: user.id?.toString(),
          email: user.email,
        });
        Sentry.captureException(extractedError);
      });
    } else {
      throw error;
    }
  }
}
