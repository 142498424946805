import { __values } from "tslib";
import { API } from '@sentry/core';
import { Status } from '@sentry/types';
import { logger, parseRetryAfterHeader, PromiseBuffer, SentryError } from '@sentry/utils';
/** Base Transport class implementation */
var BaseTransport = /** @class */function () {
  function BaseTransport(options) {
    this.options = options;
    /** A simple buffer holding all requests. */
    this._buffer = new PromiseBuffer(30);
    /** Locks transport after receiving rate limits in a response */
    this._rateLimits = {};
    this._api = new API(this.options.dsn);
    // eslint-disable-next-line deprecation/deprecation
    this.url = this._api.getStoreEndpointWithUrlEncodedAuth();
  }
  /**
   * @inheritDoc
   */
  BaseTransport.prototype.sendEvent = function (_) {
    throw new SentryError('Transport Class has to implement `sendEvent` method');
  };
  /**
   * @inheritDoc
   */
  BaseTransport.prototype.close = function (timeout) {
    return this._buffer.drain(timeout);
  };
  /**
   * Handle Sentry repsonse for promise-based transports.
   */
  BaseTransport.prototype._handleResponse = function (_a) {
    var requestType = _a.requestType,
      response = _a.response,
      headers = _a.headers,
      resolve = _a.resolve,
      reject = _a.reject;
    var status = Status.fromHttpCode(response.status);
    /**
     * "The name is case-insensitive."
     * https://developer.mozilla.org/en-US/docs/Web/API/Headers/get
     */
    var limited = this._handleRateLimit(headers);
    if (limited) logger.warn("Too many requests, backing off until: " + this._disabledUntil(requestType));
    if (status === Status.Success) {
      resolve({
        status: status
      });
      return;
    }
    reject(response);
  };
  /**
   * Gets the time that given category is disabled until for rate limiting
   */
  BaseTransport.prototype._disabledUntil = function (category) {
    return this._rateLimits[category] || this._rateLimits.all;
  };
  /**
   * Checks if a category is rate limited
   */
  BaseTransport.prototype._isRateLimited = function (category) {
    return this._disabledUntil(category) > new Date(Date.now());
  };
  /**
   * Sets internal _rateLimits from incoming headers. Returns true if headers contains a non-empty rate limiting header.
   */
  BaseTransport.prototype._handleRateLimit = function (headers) {
    var e_1, _a, e_2, _b;
    var now = Date.now();
    var rlHeader = headers['x-sentry-rate-limits'];
    var raHeader = headers['retry-after'];
    if (rlHeader) {
      try {
        // rate limit headers are of the form
        //     <header>,<header>,..
        // where each <header> is of the form
        //     <retry_after>: <categories>: <scope>: <reason_code>
        // where
        //     <retry_after> is a delay in ms
        //     <categories> is the event type(s) (error, transaction, etc) being rate limited and is of the form
        //         <category>;<category>;...
        //     <scope> is what's being limited (org, project, or key) - ignored by SDK
        //     <reason_code> is an arbitrary string like "org_quota" - ignored by SDK
        for (var _c = __values(rlHeader.trim().split(',')), _d = _c.next(); !_d.done; _d = _c.next()) {
          var limit = _d.value;
          var parameters = limit.split(':', 2);
          var headerDelay = parseInt(parameters[0], 10);
          var delay = (!isNaN(headerDelay) ? headerDelay : 60) * 1000; // 60sec default
          try {
            for (var _e = (e_2 = void 0, __values(parameters[1].split(';'))), _f = _e.next(); !_f.done; _f = _e.next()) {
              var category = _f.value;
              this._rateLimits[category || 'all'] = new Date(now + delay);
            }
          } catch (e_2_1) {
            e_2 = {
              error: e_2_1
            };
          } finally {
            try {
              if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            } finally {
              if (e_2) throw e_2.error;
            }
          }
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        } finally {
          if (e_1) throw e_1.error;
        }
      }
      return true;
    } else if (raHeader) {
      this._rateLimits.all = new Date(now + parseRetryAfterHeader(now, raHeader));
      return true;
    }
    return false;
  };
  return BaseTransport;
}();
export { BaseTransport };
