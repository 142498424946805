import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/services/config.service';
import { IConfigSetting } from '@core/services/config.model';
import { ANY_UI_COLORS_NAME, COLORS_NAME, DARK_THEME, LIGHT_THEME, ThemeKey, ThemeNamesEnum } from '../core/config/const/theme.colors';
import { OverlayContainer } from 'ngx-toastr';
import { ThemesService } from '../core/services/themes.service';
import { LoadingService } from '../core/services/loading.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit, OnDestroy {
  onSettingsChanged: Subscription;
  settings: IConfigSetting;
  showLoadingBar: boolean;
  redirect = false;
  @HostBinding('attr.adpp-layout-mode') layoutMode;
  public currentTheme: ThemeKey = ThemeNamesEnum.light;
  private unsubscribe: Subject<void> = new Subject();
  isLoading$ = this.loadingService.isLoading$;

  constructor(
    private config: ConfigService,
    private platform: Platform,
    private router: Router,
    public authService: AuthService,
    public changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemesService,
    private overlay: OverlayContainer,
    @Inject(DOCUMENT) private document: Document,
    readonly loadingService: LoadingService
  ) {
    this.onSettingsChanged = this.config.onSettingsChanged.subscribe((newSettings) => {
      this.settings = newSettings;
      this.layoutMode = this.settings.layout.mode;
      this.changeDetectorRef.markForCheck();
    });

    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.className += ' is-mobile';
    }

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showLoadingBar = true;
      }
      if (event instanceof NavigationEnd) {
        this.showLoadingBar = false;
        this.redirect = this.router.url.indexOf('redirect') > 0;
      }
    });
  }

  ngOnInit(): void {
    this.themeService.currentTheme
      .asObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((theme) => {
        this.currentTheme = theme;
        const darkClass = 'mat-dark-theme';
        if (this.currentTheme === 'dark') {
          this.overlay.getContainerElement().classList.add(darkClass);
          this.document.querySelector('body').classList.add(darkClass);
        } else {
          this.overlay.getContainerElement().classList.remove(darkClass);
          this.document.querySelector('body').classList.remove(darkClass);
        }
        this.initThemeColors(this.currentTheme);
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.onSettingsChanged.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public isLightTheme(): boolean {
    return this.currentTheme ? this.currentTheme === ThemeNamesEnum.light : true;
  }

  getDarkClasses(): string {
    if (this.currentTheme === 'dark') {
      return 'mat-dark-theme mat-app-background';
    }
    return '';
  }

  private initThemeColors(theme: ThemeKey): void {
    if (theme === ThemeNamesEnum.light) {
      this.updateThemeColors();
    } else {
      this.updateThemeColors(false);
    }
  }

  private updateThemeColors(isLight = true): void {
    const keys = [...Object.keys(COLORS_NAME), ...Object.keys(ANY_UI_COLORS_NAME)];

    keys.forEach((key) => {
      this.document.documentElement.style.setProperty(COLORS_NAME[key], isLight ? LIGHT_THEME[key] : DARK_THEME[key]);
    });
  }
}
