import { isNodeEnv } from './node';
import { snipLine } from './string';
var fallbackGlobalObject = {};
/**
 * Safely get global scope object
 *
 * @returns Global scope object
 */
export function getGlobalObject() {
  return isNodeEnv() ? global : typeof window !== 'undefined' ? window : typeof self !== 'undefined' ? self : fallbackGlobalObject;
}
/**
 * UUID4 generator
 *
 * @returns string Generated UUID4.
 */
export function uuid4() {
  var global = getGlobalObject();
  var crypto = global.crypto || global.msCrypto;
  if (!(crypto === void 0) && crypto.getRandomValues) {
    // Use window.crypto API if available
    var arr = new Uint16Array(8);
    crypto.getRandomValues(arr);
    // set 4 in byte 7
    // eslint-disable-next-line no-bitwise
    arr[3] = arr[3] & 0xfff | 0x4000;
    // set 2 most significant bits of byte 9 to '10'
    // eslint-disable-next-line no-bitwise
    arr[4] = arr[4] & 0x3fff | 0x8000;
    var pad = function (num) {
      var v = num.toString(16);
      while (v.length < 4) {
        v = "0" + v;
      }
      return v;
    };
    return pad(arr[0]) + pad(arr[1]) + pad(arr[2]) + pad(arr[3]) + pad(arr[4]) + pad(arr[5]) + pad(arr[6]) + pad(arr[7]);
  }
  // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/2117523#2117523
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    var r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-bitwise
    var v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}
/**
 * Parses string form of URL into an object
 * // borrowed from https://tools.ietf.org/html/rfc3986#appendix-B
 * // intentionally using regex and not <a/> href parsing trick because React Native and other
 * // environments where DOM might not be available
 * @returns parsed URL object
 */
export function parseUrl(url) {
  if (!url) {
    return {};
  }
  var match = url.match(/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?$/);
  if (!match) {
    return {};
  }
  // coerce to undefined values to empty string so we don't get 'undefined'
  var query = match[6] || '';
  var fragment = match[8] || '';
  return {
    host: match[4],
    path: match[5],
    protocol: match[2],
    relative: match[5] + query + fragment
  };
}
/**
 * Extracts either message or type+value from an event that can be used for user-facing logs
 * @returns event's description
 */
export function getEventDescription(event) {
  if (event.message) {
    return event.message;
  }
  if (event.exception && event.exception.values && event.exception.values[0]) {
    var exception = event.exception.values[0];
    if (exception.type && exception.value) {
      return exception.type + ": " + exception.value;
    }
    return exception.type || exception.value || event.event_id || '<unknown>';
  }
  return event.event_id || '<unknown>';
}
/** JSDoc */
export function consoleSandbox(callback) {
  var global = getGlobalObject();
  var levels = ['debug', 'info', 'warn', 'error', 'log', 'assert'];
  if (!('console' in global)) {
    return callback();
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  var originalConsole = global.console;
  var wrappedLevels = {};
  // Restore all wrapped console methods
  levels.forEach(function (level) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (level in global.console && originalConsole[level].__sentry_original__) {
      wrappedLevels[level] = originalConsole[level];
      originalConsole[level] = originalConsole[level].__sentry_original__;
    }
  });
  // Perform callback manipulations
  var result = callback();
  // Revert restoration to wrapped state
  Object.keys(wrappedLevels).forEach(function (level) {
    originalConsole[level] = wrappedLevels[level];
  });
  return result;
}
/**
 * Adds exception values, type and value to an synthetic Exception.
 * @param event The event to modify.
 * @param value Value of the exception.
 * @param type Type of the exception.
 * @hidden
 */
export function addExceptionTypeValue(event, value, type) {
  event.exception = event.exception || {};
  event.exception.values = event.exception.values || [];
  event.exception.values[0] = event.exception.values[0] || {};
  event.exception.values[0].value = event.exception.values[0].value || value || '';
  event.exception.values[0].type = event.exception.values[0].type || type || 'Error';
}
/**
 * Adds exception mechanism to a given event.
 * @param event The event to modify.
 * @param mechanism Mechanism of the mechanism.
 * @hidden
 */
export function addExceptionMechanism(event, mechanism) {
  if (mechanism === void 0) {
    mechanism = {};
  }
  // TODO: Use real type with `keyof Mechanism` thingy and maybe make it better?
  try {
    // @ts-ignore Type 'Mechanism | {}' is not assignable to type 'Mechanism | undefined'
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    event.exception.values[0].mechanism = event.exception.values[0].mechanism || {};
    Object.keys(mechanism).forEach(function (key) {
      // @ts-ignore Mechanism has no index signature
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      event.exception.values[0].mechanism[key] = mechanism[key];
    });
  } catch (_oO) {
    // no-empty
  }
}
/**
 * A safe form of location.href
 */
export function getLocationHref() {
  try {
    return document.location.href;
  } catch (oO) {
    return '';
  }
}
// https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
var SEMVER_REGEXP = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
/**
 * Parses input into a SemVer interface
 * @param input string representation of a semver version
 */
export function parseSemver(input) {
  var match = input.match(SEMVER_REGEXP) || [];
  var major = parseInt(match[1], 10);
  var minor = parseInt(match[2], 10);
  var patch = parseInt(match[3], 10);
  return {
    buildmetadata: match[5],
    major: isNaN(major) ? undefined : major,
    minor: isNaN(minor) ? undefined : minor,
    patch: isNaN(patch) ? undefined : patch,
    prerelease: match[4]
  };
}
var defaultRetryAfter = 60 * 1000; // 60 seconds
/**
 * Extracts Retry-After value from the request header or returns default value
 * @param now current unix timestamp
 * @param header string representation of 'Retry-After' header
 */
export function parseRetryAfterHeader(now, header) {
  if (!header) {
    return defaultRetryAfter;
  }
  var headerDelay = parseInt("" + header, 10);
  if (!isNaN(headerDelay)) {
    return headerDelay * 1000;
  }
  var headerDate = Date.parse("" + header);
  if (!isNaN(headerDate)) {
    return headerDate - now;
  }
  return defaultRetryAfter;
}
/**
 * This function adds context (pre/post/line) lines to the provided frame
 *
 * @param lines string[] containing all lines
 * @param frame StackFrame that will be mutated
 * @param linesOfContext number of context lines we want to add pre/post
 */
export function addContextToFrame(lines, frame, linesOfContext) {
  if (linesOfContext === void 0) {
    linesOfContext = 5;
  }
  var lineno = frame.lineno || 0;
  var maxLines = lines.length;
  var sourceLine = Math.max(Math.min(maxLines, lineno - 1), 0);
  frame.pre_context = lines.slice(Math.max(0, sourceLine - linesOfContext), sourceLine).map(function (line) {
    return snipLine(line, 0);
  });
  frame.context_line = snipLine(lines[Math.min(maxLines - 1, sourceLine)], frame.colno || 0);
  frame.post_context = lines.slice(Math.min(sourceLine + 1, maxLines), sourceLine + 1 + linesOfContext).map(function (line) {
    return snipLine(line, 0);
  });
}
/**
 * Strip the query string and fragment off of a given URL or path (if present)
 *
 * @param urlPath Full URL or path, including possible query string and/or fragment
 * @returns URL or path without query string or fragment
 */
export function stripUrlQueryAndFragment(urlPath) {
  // eslint-disable-next-line no-useless-escape
  return urlPath.split(/[\?#]/, 1)[0];
}
