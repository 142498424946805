import { __assign } from "tslib";
import { API, captureException, withScope } from '@sentry/core';
import { addExceptionMechanism, addExceptionTypeValue, logger } from '@sentry/utils';
var ignoreOnError = 0;
/**
 * @hidden
 */
export function shouldIgnoreOnError() {
  return ignoreOnError > 0;
}
/**
 * @hidden
 */
export function ignoreNextOnError() {
  // onerror should trigger before setTimeout
  ignoreOnError += 1;
  setTimeout(function () {
    ignoreOnError -= 1;
  });
}
/**
 * Instruments the given function and sends an event to Sentry every time the
 * function throws an exception.
 *
 * @param fn A function to wrap.
 * @returns The wrapped function.
 * @hidden
 */
export function wrap(fn, options, before) {
  if (options === void 0) {
    options = {};
  }
  if (typeof fn !== 'function') {
    return fn;
  }
  try {
    // We don't wanna wrap it twice
    if (fn.__sentry__) {
      return fn;
    }
    // If this has already been wrapped in the past, return that wrapped function
    if (fn.__sentry_wrapped__) {
      return fn.__sentry_wrapped__;
    }
  } catch (e) {
    // Just accessing custom props in some Selenium environments
    // can cause a "Permission denied" exception (see raven-js#495).
    // Bail on wrapping and return the function as-is (defers to window.onerror).
    return fn;
  }
  /* eslint-disable prefer-rest-params */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  var sentryWrapped = function () {
    var args = Array.prototype.slice.call(arguments);
    try {
      if (before && typeof before === 'function') {
        before.apply(this, arguments);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
      var wrappedArguments = args.map(function (arg) {
        return wrap(arg, options);
      });
      if (fn.handleEvent) {
        // Attempt to invoke user-land function
        // NOTE: If you are a Sentry user, and you are seeing this stack frame, it
        //       means the sentry.javascript SDK caught an error invoking your application code. This
        //       is expected behavior and NOT indicative of a bug with sentry.javascript.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return fn.handleEvent.apply(this, wrappedArguments);
      }
      // Attempt to invoke user-land function
      // NOTE: If you are a Sentry user, and you are seeing this stack frame, it
      //       means the sentry.javascript SDK caught an error invoking your application code. This
      //       is expected behavior and NOT indicative of a bug with sentry.javascript.
      return fn.apply(this, wrappedArguments);
    } catch (ex) {
      ignoreNextOnError();
      withScope(function (scope) {
        scope.addEventProcessor(function (event) {
          var processedEvent = __assign({}, event);
          if (options.mechanism) {
            addExceptionTypeValue(processedEvent, undefined, undefined);
            addExceptionMechanism(processedEvent, options.mechanism);
          }
          processedEvent.extra = __assign(__assign({}, processedEvent.extra), {
            arguments: args
          });
          return processedEvent;
        });
        captureException(ex);
      });
      throw ex;
    }
  };
  /* eslint-enable prefer-rest-params */
  // Accessing some objects may throw
  // ref: https://github.com/getsentry/sentry-javascript/issues/1168
  try {
    for (var property in fn) {
      if (Object.prototype.hasOwnProperty.call(fn, property)) {
        sentryWrapped[property] = fn[property];
      }
    }
  } catch (_oO) {} // eslint-disable-line no-empty
  fn.prototype = fn.prototype || {};
  sentryWrapped.prototype = fn.prototype;
  Object.defineProperty(fn, '__sentry_wrapped__', {
    enumerable: false,
    value: sentryWrapped
  });
  // Signal that this function has been wrapped/filled already
  // for both debugging and to prevent it to being wrapped/filled twice
  Object.defineProperties(sentryWrapped, {
    __sentry__: {
      enumerable: false,
      value: true
    },
    __sentry_original__: {
      enumerable: false,
      value: fn
    }
  });
  // Restore original function name (not all browsers allow that)
  try {
    var descriptor = Object.getOwnPropertyDescriptor(sentryWrapped, 'name');
    if (descriptor.configurable) {
      Object.defineProperty(sentryWrapped, 'name', {
        get: function () {
          return fn.name;
        }
      });
    }
    // eslint-disable-next-line no-empty
  } catch (_oO) {}
  return sentryWrapped;
}
/**
 * Injects the Report Dialog script
 * @hidden
 */
export function injectReportDialog(options) {
  if (options === void 0) {
    options = {};
  }
  if (!options.eventId) {
    logger.error("Missing eventId option in showReportDialog call");
    return;
  }
  if (!options.dsn) {
    logger.error("Missing dsn option in showReportDialog call");
    return;
  }
  var script = document.createElement('script');
  script.async = true;
  script.src = new API(options.dsn).getReportDialogEndpoint(options);
  if (options.onLoad) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    script.onload = options.onLoad;
  }
  (document.head || document.body).appendChild(script);
}
