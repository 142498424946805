import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ToasterService } from '../services/toaster.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toasterService: ToasterService,
    private translate: I18NextPipe
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
        } else if (error.status === 500) {
          this.toasterService.showErrorToastr({
            message: this.translate.transform('MESSAGE.SERVER_ERROR'),
          });
        } else if (error.status === 0) {
          // status 0 means request is blocked by the browser
          console.warn('request has been canceled or blocked by the browser');
        }
        return throwError(error);
      })
    );
  }
}
