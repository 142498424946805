import { Status } from '@sentry/types';
import { SyncPromise } from '@sentry/utils';
/** Noop transport */
var NoopTransport = /** @class */function () {
  function NoopTransport() {}
  /**
   * @inheritDoc
   */
  NoopTransport.prototype.sendEvent = function (_) {
    return SyncPromise.resolve({
      reason: "NoopTransport: Event has been skipped because no Dsn is configured.",
      status: Status.Skipped
    });
  };
  /**
   * @inheritDoc
   */
  NoopTransport.prototype.close = function (_) {
    return SyncPromise.resolve(true);
  };
  return NoopTransport;
}();
export { NoopTransport };
